const wrapper = (theme) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: theme.spacing(4),
})

const link = (theme) => ({
  marginTop: theme.spacing(1),
})

const hint = (theme) => ({
  marginBottom: theme.spacing(4),
})

export const styles = {
  wrapper,
  link,
  hint,
}
