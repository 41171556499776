/** @jsxImportSource @emotion/react */
import { useContext } from 'react'
import * as yup from 'yup'
import { Formik, Form } from 'formik'
import { FormContext } from '../../../context/form-context'
import { locales } from '../../../../lib/common/enums/locales'
import { Contact, FormikNavigation, Section, ValidationAlert } from '../../../components'
import SpecificationDE from './specifications/specifications-de'
import SpecificationAT from './specifications/specifications-at'
import Type from './type/type'
import Dimensions from './dimensions/dimensions'
import { content } from './step2.content'
import config from '../../../../lib/config/de-at.config.json'
import { regulations } from '../../../../lib/common/enums/regulations'
import { useRoofValidationApi } from '../../../hooks/use-api'
import { useState } from 'react'

const dimensions = config.building.properties.dimensions
const roofKinds = config.building.properties.roofKinds

const dimensionSchema = (formState) => ({
  height: yup.mixed().when({
    is: () => formState.location?.regulation === regulations.DEFAULT_AT,
    then: yup
      .number()
      .min(0, dimensions.height.errors.atDefault)
      .max(25, dimensions.height.errors.atDefault)
      .required(content.errors.required),
    otherwise: yup
      .number()
      .min(0, dimensions.height.errors.main)
      .max(25, dimensions.height.errors.main)
      .required(content.errors.required),
  }),
  width: yup.number().min(0, dimensions.width.error).required(content.errors.required),
  length: yup.number().min(0, dimensions.length.error).required(content.errors.required),
  roofPitch: yup.mixed().when({
    is: () => formState.location?.regulation === regulations.DEFAULT_AT,
    then: yup
      .number()
      .min(10, dimensions.roofPitch.errors.atDefault)
      .max(75, dimensions.roofPitch.errors.atDefault)
      .required(content.errors.required),
    otherwise: yup
      .number()
      .min(7, dimensions.roofPitch.errors.main)
      .max(75, dimensions.roofPitch.errors.main)
      .required(content.errors.required),
  }),
  distanceUpperLowerShell: yup.mixed().when({
    is: () => formState?.location?.regulation === regulations.EXTENDED_AT,
    then: yup
      .number()
      .min(0, dimensions.distanceUpperLowerShell.error)
      .max(100, dimensions.distanceUpperLowerShell.error)
      .required(content.errors.required),
  }),
})

const dimensionSchemaExtended = (formState) => ({
  ...dimensionSchema(formState),
  length: yup.mixed().when(['width'], (w) => {
    const defaultSchema = yup.number().min(0, dimensions.length.error).required(content.errors.required);
    if(!w) {
      return defaultSchema;
    }

    w = parseInt(w);
    return yup.number().min(w + 1, dimensions.length.widthDependentError).required(content.errors.required);
  }),
  hipPitch: yup.mixed().when({
    is: () => formState.location?.regulation === regulations.DEFAULT_AT,
    then: yup
      .number()
      .min(10, dimensions.hipPitch.errors.atDefault)
      .max(75, dimensions.hipPitch.errors.atDefault)
      .required(content.errors.required),
    otherwise: yup
      .number()
      .min(7, dimensions.hipPitch.errors.main)
      .max(75, dimensions.hipPitch.errors.main)
      .required(content.errors.required),
  }),
})

const validationSchema = (formState) => {
  return yup.object().shape({
    buildingType: yup.string('').required(content.errors.required),
    buildingProject: yup.string().required(content.errors.required),
    roofStructure: yup.mixed().when({
      is: () => formState.place.locale === locales.germany,
      then: yup.string().required(content.errors.required),
    }),
    roofKind: yup.string().required(content.errors.required),
    dimensions: yup.mixed().when(['roofKind'], {
      is: (id) => id === roofKinds.items[2].id,
      then: yup.object().shape(dimensionSchemaExtended(formState)),
      otherwise: yup.object().shape(dimensionSchema(formState)),
    }),
  })
}

const initialValues = (formState) => ({
  buildingType: '',
  buildingProject: '',
  roofStructure: '',
  roofKind: formState.roofKind,
  isUnderRoof: formState.isUnderRoof,
  width: 10,
  dimensions: {
    height: '',
    width: '',
    length: '',
    roofPitch: '',
    hipPitch: '',
    distanceUpperLowerShell: '',
  },
})

export default function Step2({ nextStep, goBack, ...props }) {
  const [isValidationError, setValidationError] = useState(false)
  const { formState, setFormState } = useContext(FormContext)

  const {
    isLoading,
    mutate: validateRoof,
    errorMessage,
  } = useRoofValidationApi({
    onSuccess: () => {
      nextStep()
    },
    onError: () => {
      setValidationError(true)
    },
  })

  const handleSubmit = async (values) => {
    const newState = { ...formState, ...values }
    setFormState(newState)
    validateRoof(newState)
  }

  const handleChange = () => {
    if (isValidationError) {
      setValidationError(false)
    }
  }

  return (
    <div {...props}>
      <Formik
        initialValues={initialValues(formState)}
        validationSchema={validationSchema(formState)}
        onSubmit={handleSubmit}
      >
        <Form>
          <Type />
          {formState.place.locale === locales.germany && <SpecificationDE />}
          {formState.place.locale === locales.austria && <SpecificationAT />}
          <Dimensions onChange={handleChange} />

          {isValidationError && (
            <Section spacing="topMedium">
              <ValidationAlert severity="error" title={content.errors.roofValidation} error={errorMessage}>
                <Contact />
              </ValidationAlert>
            </Section>
          )}

          <Section spacing="verticalLarge">
            <FormikNavigation onBack={() => goBack()} loading={isLoading} />
          </Section>
        </Form>
      </Formik>
    </div>
  )
}
